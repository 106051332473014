export default class AppFooter {
  constructor(container, options) {
    const defaultOptions = {
    }

    this.options = Object.assign({}, defaultOptions, options)
    this.container = document.querySelector(container)
    this.locations = this.container.querySelectorAll('.location')

    this.locations.forEach(location => {
      location.addEventListener('click', this.onClickLocation.bind(this))
    })
  }

  onClickLocation(event) {
    const location = event.currentTarget

    if (location.classList.contains('active')) {
      location.classList.remove('active')
    } else {
      this.locations.forEach(loc => loc.classList.remove('active'))
      location.classList.add('active')
    }
  }
}